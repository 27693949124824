import {AbstractFormComponent} from "./AbstractFormComponent";
import {DI} from "ui-base/src/_resources/ts/DI/DI";

declare let $: any;

export abstract class AjaxFormComponent extends AbstractFormComponent
{
	public static selector: string = 'ajax-form-component';
	private ajaxOptions: {} = {};
	private ajaxHeaders: {} = {};

	public constructor(componentElement: any, DI: DI) {
		super(componentElement, DI);

		if(this.getComponentElement().attr('ajax-auto-submit-on-load'))
		{
			this.submitOnLoad();
		}
	}

	public addAjaxOption(key: string, value: any)
	{
		this.ajaxOptions[key] = value;
		return this;
	}

	public addAjaxHeader(key: string, value: any)
	{
		this.ajaxHeaders[key] = value;
		return this;
	}

	protected submitOnLoad()
	{
		$(window).on('load', () => {
			this.handleFormSubmission();
		});
	}

	protected async handleFormSubmission(event: any = null): Promise<void>
	{
		event.preventDefault();

		let valid = await this.validateInputs(event);

		this.preSubmitActions();

		this.setButtonToProcessing(true);

		if(valid)
		{
			let endpoint = $(this.getComponentElement()).attr('ajax-endpoint');

			if(endpoint)
			{
				this.ajaxCall(endpoint, this.getComponentElement().serialize())
					.then((data) => {
						if (this.getComponentElement().attr(this.shouldButtonsResetAttr) != 'false')
						{
							this.setButtonToProcessing(false);
						}

						if (data['success'] == false)
						{
							this.onResponseFail(data);
						}
						else
						{
							this.onResponseSuccess(data);
						}
					})
					.catch((error) => {
						this.setButtonToProcessing(false);
						this.onResponseFail(error);
					})
			}
			else
			{
				this.setButtonToProcessing(false);
				console.error('No endpoint set on the form!');
			}
			this.postSubmitActions();
		}
		else
		{
			this.setButtonToProcessing(false);
		}
	}

	protected async ajaxCall(url: string, data: string, type: string = "POST", crossDomain: boolean = true, dataType: string = 'json') {

		let headers = {
			'X-Requested-With': 'XMLHttpRequest',
		};

		let combinedHeaders = {
			...headers,
			...this.ajaxHeaders
		};

		let options = {
			url: url,
			type: type,
			headers: combinedHeaders,
			data: data
		};

		let combinedOptions = {
			... options,
			... this.ajaxOptions
		};

		return $.ajax(combinedOptions);
	}

	protected onResponseSuccess(data) {
	}

	protected onResponseFail(error) {
	}

	protected setupFormSubmitEventListener(): this {
		// setup event listener for the form submit event
		this.getComponentElement().on(
			'submit',
			this.handleFormSubmission.bind(this)
		);
		return this;
	}

	protected redirect()
	{
		window.location.href = this.getComponentElement().attr('redirect-url');
	}

	protected displaySuccessMessage()
	{
		this.hideMessages();
		this.getComponentElement().find('.ajax-message--success').addClass('ajax-message--show');
		this.getComponentElement().find('[ajax-success-message]').html(this.getSuccessMessage());
	}

	protected displayErrorMessage(errorCode:any = null)
	{
		this.hideMessages();
		this.getComponentElement().find('.ajax-message--error').addClass('ajax-message--show');
		this.getComponentElement().find('[ajax-error-message]').html(this.getErrorMessage(errorCode));
	}

	protected hideMessages()
	{
		this.getComponentElement().find('.ajax-message--show').removeClass('ajax-message--show');
	}

	protected getErrorMessage(errorCode:any = null)
	{
		let errorMessages = JSON.parse(this.getComponentElement().attr('error-messages'));
		if(errorCode)
		{
			if(errorMessages[errorCode])
			{
				return errorMessages[errorCode];
			}
		}

		return errorMessages.default;
	}

	protected getSuccessMessage()
	{
		return this.getComponentElement().attr('success-message');
	}

	// This is to be used when an extra action need to be performed on the form prior to submit
	// for example: src/Components/Form/MagicLinkVerificationForm/_resources/ts/MagicLinkVerificationForm.ts:17
	protected preSubmitActions() {
	}

	protected postSubmitActions() {
	}
}

